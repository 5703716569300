// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var $dot$dot$slash$dot$dot$slash$dot$dot$slash = require("../../../");
var Css_Color = require("../../../../reason/common/Theme/Css_Color.bs.js");
var JsxRuntime = require("react/jsx-runtime");

function iconToString(x) {
  switch (x) {
    case "AddNote" :
        return "add-note";
    case "Add" :
        return "add";
    case "ArrowDoubleLeft" :
        return "arrow-double-left";
    case "ArrowDoubleRight" :
        return "arrow-double-right";
    case "ArrowDownAlt" :
        return "arrow-down-alt";
    case "ArrowDown" :
        return "arrow-down";
    case "ArrowLeft" :
        return "arrow-left";
    case "ArrowRight" :
        return "arrow-right";
    case "ArrowUpAlt" :
        return "arrow-up-alt";
    case "ArrowUp" :
        return "arrow-up";
    case "AttentionAlt" :
        return "attention-alt";
    case "Attention" :
        return "attention";
    case "Bed" :
        return "bed";
    case "BellFilled" :
        return "bell-filled";
    case "Bell" :
        return "bell";
    case "Bullet" :
        return "bullet";
    case "Buyer" :
        return "buyer";
    case "Calendar" :
        return "calendar";
    case "CancelCircled" :
        return "cancel-circled";
    case "Cancel" :
        return "cancel";
    case "Chat" :
        return "chat";
    case "Client" :
        return "client";
    case "Clock" :
        return "clock";
    case "Comment" :
        return "comment";
    case "Details" :
        return "details";
    case "Draft" :
        return "draft";
    case "Email" :
        return "email";
    case "Error" :
        return "error";
    case "Exclamation" :
        return "exclamation";
    case "Eye" :
        return "eye";
    case "Facebook" :
        return "facebook";
    case "Favorite" :
        return "favorite";
    case "FileDoc" :
        return "file-doc";
    case "FilePdf" :
        return "file-pdf";
    case "FilePng" :
        return "file-png";
    case "File" :
        return "file";
    case "Filters" :
        return "filters";
    case "Follow" :
        return "follow";
    case "FullSizeView" :
        return "full-size-view";
    case "Home" :
        return "home";
    case "Image" :
        return "image";
    case "Instagram" :
        return "instagram";
    case "LightBulbAlt" :
        return "light-bulb-alt";
    case "LightBulb" :
        return "light-bulb";
    case "Link" :
        return "link";
    case "LinkedIn" :
        return "linked-in";
    case "Location" :
        return "location";
    case "Lock" :
        return "lock";
    case "MegaphoneAlt" :
        return "megaphone-alt";
    case "Megaphone" :
        return "megaphone";
    case "MobileMenu" :
        return "mobile-menu";
    case "NoAlertsBell" :
        return "no-alerts-bell";
    case "OkCircled" :
        return "ok-circled";
    case "OkCircledAlt" :
        return "ok-circled-alt";
    case "OkSemicircled" :
        return "ok-semicircled";
    case "Ok" :
        return "ok";
    case "Pencil" :
        return "pencil";
    case "People" :
        return "people";
    case "Phone" :
        return "phone";
    case "Picture" :
        return "picture";
    case "Pinterest" :
        return "pinterest";
    case "Question" :
        return "question";
    case "ReactSelectCancel" :
        return "react-select-cancel";
    case "Search" :
        return "search";
    case "Seller" :
        return "seller";
    case "Share" :
        return "share";
    case "Stats" :
        return "stats";
    case "ThreeDots" :
        return "three-dots";
    case "ThumbUp" :
        return "thumb-up";
    case "Tick" :
        return "tick";
    case "Trash" :
        return "trash";
    case "Twitter" :
        return "twitter";
    case "Unable" :
        return "unable";
    case "Unavailable" :
        return "unavailable";
    case "Upload" :
        return "upload";
    case "UserProfile" :
        return "user-profile";
    case "ViewDetails" :
        return "view-details";
    case "Yelp" :
        return "yelp";
    case "Youtube" :
        return "youtube";
    
  }
}

var make = $dot$dot$slash$dot$dot$slash$dot$dot$slash.Icon;

var JS = {
  make: make
};

function Icon__Jsx3(props) {
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx(make, {
              icon: iconToString(props.icon),
              className: className,
              size: props.size,
              color: Css_Color.optToJs(props.color)
            });
}

var make$1 = Icon__Jsx3;

exports.iconToString = iconToString;
exports.JS = JS;
exports.make = make$1;
/* make Not a pure module */
